import { useDispatch } from 'react-redux'
import { userLogin } from 'redux-store/thunk/auth.thunk'
import { useLocation, useHistory } from 'react-router-dom'
import { useEffect } from 'react'
import { ROUTE_PATHS } from 'utils/constants/index'

const useOperatorAuthentication = () => {
  const dispatch = useDispatch()
  const { search } = useLocation()
  const history = useHistory()
  const operatorId = new URLSearchParams(search).get('operator')
  const playerToken = new URLSearchParams(search).get('token')
  const currencyCode = new URLSearchParams(search).get('currency')

  const handleLoginUser = () => {
    const params = {
      payload: {
        operatorId,
        operatorUserToken: playerToken,
        currencyCode
      },
      history
    }
    dispatch(userLogin(params))
  }

  useEffect(() => {
    if (playerToken && currencyCode && operatorId) {
      handleLoginUser()
    } else {
      history.push(ROUTE_PATHS.home)
    }
  }, [playerToken, currencyCode, operatorId])
}

export default useOperatorAuthentication
